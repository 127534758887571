<template>
  <div>
    <div class="hidden-xs-only">
      <div class="ml-nav"  id="bignav">
        <div class="center-wrap clearfix">
          <h2 class="ml-logo">
            <a href="/#">
              <img :src="config.logo" alt="">
            </a>
          </h2>
          <ul class="ml-link" style="float:left;"  >
            <li style="list-style-type:none;float:left;margin-left:300px" >
              <a @click="$router.replace({name: 'Home'})" style="display: inline-block;margin: 0 15px;font-size: 15px;font-weight: bold;cursor:pointer;">首页</a>
            </li>

            <template v-for="(type, i) in types">
              <li v-if="type.status === 1" style="list-style-type:none;float:left;"  :key="type.id">
                <a style="cursor:pointer;display: inline-block;margin: 0 15px;font-size: 15px;font-weight: bold;" @click="updateCurrentType(type),updateCurrentSubType(type.subMap && type.subMap.length > 0 ? type.subMap[0] : null),$router.push({ name: 'Type',query:{typeId: type.id, subTypeId: type.subMap && type.subMap.length > 0 ? type.subMap[0].id : ''} })">{{ type.name }}
                </a>
                <div v-if="type.subMap && type.subMap.length >0" :id="'sonnav0' + (i+1)" style="cursor:pointer;line-height: 20px;background:#fff;display:none;overflow:hidden;margin-top:10px;width:80px;">
                  <a v-for="sub in type.subMap" :key="sub.id"
                     @click="updateCurrentType(type),updateCurrentSubType(sub),$router.replace({ name: 'Type',query:{typeId: type.id, subTypeId:sub.id} })"
                     style="margin:0px;display:block;width:100%;border-bottom:1px solid #d1a262;font-size:12px;text-align:center;">{{
                      sub.name
                    }}</a>
                </div>
              </li>
            </template>

          </ul>
        </div>

      </div>
      <div id="sebox" style="border:1px solid #d1a262;height: 30px;line-height: 30px;margin-right: 60px;margin-top:0px;position: fixed;z-index: 9999;top:20px;width: 230px;display: flex;">
        <el-input :rows="1" class="custom-height" v-model="keyword" style="float:left;width:150px;height:30px;line-height:30px;"/>
        <button @click="$emit('keyword-change', keyword)" style="display:block;float:left;background:#d1a262;color:#fff;border:1px solid #d1a262;height:30px;line-height:30px;width:80px;text-align:center;cursor:pointer;">搜索</button>
      </div>
    </div>
    <div class="hidden-sm-and-up">
      <div class="top_header" style="display: flex;align-items: center;justify-content: space-between;">
        <a ><img src="../assets/images/top_big_logo.png" alt="" />
        </a>
        <div @click="$router.push({name: 'SearchList'})">
          <van-icon name="search" size="30" color="black"/>
        </div>
      </div>
      <div class="nav_box_a" style="background:#c99f63">
        <div class="inner">
          <ul>
            <li :class="!currentType ? 'ccur':''"  :key="999">
                <a style="cursor:pointer;" @click="updateCurrentType(null),updateCurrentSubType(null),$router.push({name:'Home'})">首页
                  <i></i>
                </a>
              </li>
            <template v-for="(type, i) in types">
              
              <li :class="currentType && currentType.id === type.id ? 'ccur':''" v-if="type.status === 1" :key="i">
                <a style="cursor:pointer;" @click="updateCurrentType(type),updateCurrentSubType(type.subMap && type.subMap.length > 0 ? type.subMap[0] : null),$router.push({ name: 'Type',query:{typeId: type.id, subTypeId: type.subMap && type.subMap.length > 0 ? type.subMap[0].id : ''} })">{{type.name}}
                  <i></i>
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      keyword: ''
    }
  },
  mounted() {
    this.keyword = this.$route.query.keyword;
    // eslint-disable-next-line no-undef
    var allwidth=parseInt($(window).width());
    // eslint-disable-next-line no-undef
    var menuwidth=parseInt($(".center-wrap").css("width").replace("px",""));
// eslint-disable-next-line no-undef
    $("#sebox").css({"left":(((allwidth-1500)/2)+menuwidth)+"px"});

    this.$nextTick(()=>{
      // eslint-disable-next-line no-undef
      $("#bignav li").mouseover(function () {
        const divs = this.getElementsByTagName('div');
        if (divs.length > 0) {
          // eslint-disable-next-line no-undef
          $(divs[0]).css({"display": "block"});
        }

      });

      // eslint-disable-next-line no-undef
      $("#bignav li").mouseout(function () {
        const divs = this.getElementsByTagName('div');
        if (divs.length > 0) {
          // eslint-disable-next-line no-undef
          $(divs[0]).css({"display": "none"});
        }
      });
    });
  },
  computed: {
    ...mapGetters(['config','types','currentType'])
  },
  methods: {
    ...mapActions(['updateAdTypes', 'updateCurrentSubType', 'updateCurrentType', 'updateTypes', 'updateConfig']),
  },
}
</script>

<style  scoped>

/deep/ .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.ml-nav{position: fixed;top: 0;left: 0;z-index: 10;width: 100%;padding: 20px 0;border-bottom:1px solid #f1f1f1;background:#FFF url(../assets/images/top_header_r_big_bg.png) no-repeat right top;background-size:auto 100%;}
.ml-logo{position: absolute;left: 0;top:-10px;}
.ml-logo img{width:189px;}
.ml-link{float: right;margin: 5px 0px 0 0;font-size: 0;}
.ml-link a{display: inline-block;margin: 0 15px;font-size: 15px;font-weight: bold;}
.buy-now{position: absolute;right: 0;top: 0;width: 100px;height: 36px;line-height: 36px;text-align: center;color: #fff;background-color: #3db1fa;}
.buy-now:hover{color: #fff;}
.center-wrap{position: relative;z-index: 1;width: 1200px;height: 100%;margin: 0 auto;}
.desc{position: relative;z-index: 1;}
.section{overflow: hidden;}
.section h1{line-height: 1.4;font-size: 40px;font-weight: 400;width: 400px;margin:auto;margin-bottom:30px;}
.section h1 img{width: 100%;}
.section p{line-height: 25px;}
.section .meizu-logo{position: absolute;left: 0;top: 0;}
.meizu-logo a img{width:189px;}
.nav{float: right;}
.nav li{float: left;}
.nav a{display: block;padding: 31px 22px 30px;}
.top_header{background:url(../assets/images/top_header_r_bg.png) no-repeat right top;background-size:auto 100%;padding:10px;}
.top_header img{width:150px;}
.nav_box_a .inner{padding:10px;}
.nav_box_a li{float: left;width:33.3%;}
.nav_box_a ul{overflow: hidden;}
.nav_box_a li a{display: block;text-align: center;padding:5px 0;font-weight: 600;font-size: 14px;font-weight: normal;color:#fff;}
.nav_box_a li.cur a{color:#d1a262}
.nav_box_a li.ccur a{color:#000;background:#fff}
</style>
