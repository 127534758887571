<template>
  <div>
    <div class="footer_dv news_footer_dv hidden-xs-only">
      <div class="w copyright center-wrap">
        <p>
          <a @click="clearType(),$router.push({name: 'Detail', query:{articleId:1, adId:13}})" style="cursor: pointer;">走进南京旅游集团</a> |
          <a @click="updateTypeWithName('集团要闻'),$router.push({name: 'Type'})" style="cursor: pointer;">新闻中心</a>|
          <a @click="clearType(),$router.push({name: 'Detail', query:{articleId:7489, adId:13}})" style="cursor: pointer;">监督举报</a>|
          <a @click="clearType(),$router.push({name: 'Detail', query:{articleId:31, adId:13}})" style="cursor: pointer;">联系我们</a>|
          <a href="https://oa.njlyjt.cn/login/Login.jsp?logintype=1">内部用户登录</a>
        </p>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" v-html="config ? config.copyright: ''">
        </a>
      </div>
    </div>
    <div class="weui-footer hidden-sm-and-up">
      <p class="weui-footer__links">
        <a @click="clearType(),$router.push({name: 'Detail', query:{articleId:1, adId:13}})" class="weui-footer__link">走进南京旅游集团</a>
        <a @click="updateTypeWithName('集团要闻'),$router.push({name: 'Type'})" class="weui-footer__link">新闻中心</a>
        <a @click="clearType(),$router.push({name: 'Detail', query:{articleId:31, adId:13}})" class="weui-footer__link">联系我们</a>
      </p>
      <p class="weui-footer__text">版权所有：南京旅游集团有限责任公司 <a href="http://beian.miit.gov.cn">备案号苏ICP备18011724号</a><br/>
      </p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['config'])
  },
  methods: {
    ...mapActions(['updateTypeWithName', 'clearType'])
  }
}
</script>

<style scoped>
div.news_footer_dv {
  background: url(../assets/images/news/footer_bg.gif) no-repeat center bottom;
  background-size: 100%;
  color: #FFF;
}

div.news_footer_dv .center-wrap a {
  color: #FFF;
}

div.news_footer_dv .center-wrap p {
  color: #FFF;
}

div.news_footer_dv .center-wrap {
  padding: 50px 0 80px 0;
}

.footer_dv {
  background: #f0eff1 url(../assets/images/footer_l_bg.png) no-repeat left bottom;
  background-size: auto 100%;
  text-align: center;
}

.footer_dv .navs_dv {
  background: #c4a06f;
  color: #FFF;
  padding: 15px 0;
}

.footer_dv .navs_dv a {
  color: #FFF;
  margin: 0 20px;
}

.footer_dv .navs_dv a:hover, .footer_dv .center-wrap a:hover {
  color: #986326;
}

.footer_dv .center-wrap {
  color: #877f7d;
  padding: 40px 0;
}

.footer_dv .center-wrap p {
  padding: 5px 0;
}

.footer_dv .center-wrap a {
  margin: 0 10px;
  color: #877f7d;
}

.weui-footer {
  padding: 10px 0;
  background: url(../assets/images/footer_bg.gif) no-repeat center 0;
  color: #FFF;
  font-size: 11px;
}

.weui-footer a {
  color: #FFF;
  font-size: 12px;
}
</style>
