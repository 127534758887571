<template>
  <div>
    <div class="main_wrap new_main_wrap hidden-xs-only">
      <div class="header_dv news_header_dv">
        <div class="top_header_big_box">
          <img :src="config.logo" alt="">
        </div>
        <div class="top_big_img_box" :style="`height:${imgHeight};`">
          <!--slider-->
          <template v-if="adTypes.find(v => v.id === 80)">
            <el-carousel trigger="click" :height="imgHeight">
              <el-carousel-item v-for="i in adTypes.find(v => v.id === 80).subMap" :key="i.id">
                <div class="silder-main-img" :key="i.id"
                  :style="`height:100%;background:url(${i.pic}) 50% bottom no-repeat;background-size:100% 100%;`">
                </div>
              </el-carousel-item>
            </el-carousel>
          </template>
          <!--slider-->
        </div>
        <div class="nav_wrap">
          <div class="left_z"></div>
          <div class="right_z"></div>
          <div class="navs_dv w news_navs">
            <i class="left_i"></i>
            <div class="selected_div"><img src="../assets/images/news/news_top_banner_bd_1.png" alt=""></div>
            <i class="right_i"></i>
            <ul class="clearfix">
              <li class="cur">
                <a href="#/">首页</a>
              </li>

              <template v-for="(type, i) in types">
                <li v-if="type.status === 1" style="position: relative;" class="" :key="type.id">
                  <a style="cursor:pointer;"
                    @click="updateCurrentType(type), updateCurrentSubType(type.subMap && type.subMap.length > 0 ? type.subMap[0] : null), $router.push({ name: 'Type', query: { typeId: type.id, subTypeId: type.subMap && type.subMap.length > 0 ? type.subMap[0].id : '' } })">{{
            type.name
          }}
                    <i></i>
                  </a>
                  <div v-if="type.subMap && type.subMap.length > 0" :id="'sonnav0' + (i + 1)"
                    style="cursor:pointer;line-height:20px;position:absolute;top:30px;left:0px;z-index:999;background:#fff;display:none;width:80px;">
                    <a v-for="sub in type.subMap" :key="sub.id"
                      @click="updateCurrentType(type), updateCurrentSubType(sub), $router.push({ name: 'Type', query: { typeId: type.id, subTypeId: sub.id } })"
                      style="display:block;width:100%;border-bottom:1px solid #d1a262;font-size:12px;text-align:center;">{{
            sub.name
          }}</a>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <div
        style="height: 35px;line-height: 35px;margin-right: 60px;margin-top: -65px;position: absolute;z-index: 9999;right: 0px;width: 230px;">
        <div>
          <el-input v-model="keyword" style="float:left;width:150px;height:30px;line-height:30px;" />
          <button @click="$router.push({ name: 'SearchList', query: { keyword: keyword } })"
            style="display:block;float:left;background:#d1a262;color:#fff;border:1px solid #d1a262;height:30px;line-height:30px;width:80px;text-align:center;cursor:pointer;">
            搜索
          </button>
        </div>
      </div>
      <div class="indexcontent_dv indexcontent_news_dv ">
        <div class="item_dv bg_item">
          <div class="wrapper w clearfix">
            <!-- news-mod -->
            <div class="section news-mod">
              <div class="ft_table">
                <table>
                  <tr>
                    <td>
                      <h2 style="float:left;">集团要闻
                        <i class="icon_n_02"></i>
                      </h2>
                      <a @click="jumpToNews('集团要闻')" style="float:right;color:#d1a262;cursor: pointer;">more>></a>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="news-tab clearfix">
                <ul class="pic">
                  <template v-for="i in news">
                    <li @click="jumpToNews('集团要闻', i)" :key="i.id" style="display: list-item;">
                      <a v-html="i.pic">
                      </a>
                    </li>
                  </template>
                </ul>
                <ul class="news-title">
                  <template v-for="i in news">
                    <li :key="i.id" class="">
                      <span class="icon"></span>
                      <h3 @click="jumpToNews('集团要闻', i)">
                        {{ i.title }}
                      </h3>
                      <p @click="jumpToNews('集团要闻', i)">
                        {{ i.content }}
                      </p>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <!-- video-mod -->
            <div class="section video-mod">

              <div class="ft_table">
                <table>
                  <tr>
                    <td width="100%">
                      <h2 style="float:left;">党建工作
                        <i class="icon_n_03"></i>
                      </h2>
                      <a @click="jumpToNews('党建工作')" style="float:right;color:#d1a262;cursor: pointer;">more>></a>
                      <div style="width:100%;clear:both;"></div>

                      <div class="txt_box" style="width:360px;" @click="jumpToNews('党建工作', article)">
                        <h3
                          style='margin-bottom:13px;text-align:center;height: 27px;font: 100 16px/27px ;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;'>
                          {{ article.title }}</h3>
                        {{ article.content }}
                        <a>显示更多</a>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

            </div>
          </div>
        </div>

        <div class="item_dv">

          <div class="media-list w">
            <h2 style="float:left;">企业动态
              <i class="icon_n_01"></i>
            </h2>
            <a @click="jumpToNews('企业动态')" style="float:right;color:#d1a262;cursor: pointer;">more>></a>
            <div style="width:100%;height:5px;clear:both;"></div>

            <div class="wrapper">

              <ul class="clearfix">
                <template v-for="i in activities">
                  <li :key="i.id" @click="jumpToNews('企业动态', i)">
                    <a v-html="i.pic">
                    </a>
                    <div class="inner">
                      <a class="title"
                        style="height: 27px;font: 100 16px/27px ; white-space: nowrap;text-overflow:ellipsis;overflow: hidden;font-weight: 800;">
                        {{ i.title }}
                      </a>
                      <p>
                        {{ i.content }}
                      </p>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
        <div class="item_dv">
          <div class="title_dv">
            <div class="w">
              <img src="../assets/images/title_b_bg.png" alt="">
            </div>
          </div>
          <div class="activities_content_dv w">
            <div class="clearfix">
              <div class="fr_dv fr">
                <div class="title_dv">
                  <div class="ov_dv clearfix">
                    <div class="iconbox fl">
                      <img src="../assets/images/title_l_icon_01.png" alt="">
                    </div>
                    <div class="c_dv" style="text-align: left;">
                      <h3>国企改革</h3>
                      <h3>三年行动</h3>
                    </div>
                  </div>
                </div>
                <a @click="jumpToNews('国企改革')">
                  <div class="inner">
                    <img
                      :src="adTypes.find(v => v.id === 82) && adTypes.find(v => v.id === 82).subMap[0] ? adTypes.find(v => v.id === 82).subMap[0].pic : ''"
                      alt="">
                  </div>
                </a>
              </div>
              <div class="fl_dv fl">
                <div class="title_dv">
                  <div class="ov_dv clearfix">
                    <div class="iconbox fl">
                      <img src="../assets/images/title_l_icon_01.png" alt="">
                    </div>
                    <div class="c_dv" style="text-align: left;">
                      <h3>近期活动</h3>
                      <p>Recent Activities</p>
                      <a @click="jumpToNews('近期活动')" style="float:right;color:#d1a262;margin-top:-40px;">more>></a>
                    </div>
                  </div>
                </div>
                <div class="tb_box">
                  <div class="top_lf_box clearfix">
                    <div class="p_b_10 fl ab_a">
                      <a
                        :href="adTypes.find(v => v.id === 81) && adTypes.find(v => v.id === 81).subMap[0] ? adTypes.find(v => v.id === 81).subMap[0].link : ''">
                        <img
                          :src="adTypes.find(v => v.id === 81) && adTypes.find(v => v.id === 81).subMap[0] ? adTypes.find(v => v.id === 81).subMap[0].pic : ''"
                          alt="">
                        <p class="p01"></p>
                      </a>
                    </div>
                    <div class="p_b_10 fl ab_b">
                      <a
                        :href="adTypes.find(v => v.id === 84) && adTypes.find(v => v.id === 84).subMap[0] ? adTypes.find(v => v.id === 84).subMap[0].link : ''">
                        <img
                          :src="adTypes.find(v => v.id === 84) && adTypes.find(v => v.id === 84).subMap[0] ? adTypes.find(v => v.id === 84).subMap[0].pic : ''"
                          alt="">
                        <p class="p02"></p>
                      </a>
                    </div>
                  </div>
                  <div class="c_b_box">

                    <div class="ab_c">
                      <a
                        :href="adTypes.find(v => v.id === 85) && adTypes.find(v => v.id === 85).subMap[0] ? adTypes.find(v => v.id === 85).subMap[0].link : ''">
                        <img
                          :src="adTypes.find(v => v.id === 85) && adTypes.find(v => v.id === 85).subMap[0] ? adTypes.find(v => v.id === 85).subMap[0].pic : ''"
                          alt="">
                        <p class="p03"></p>
                      </a>
                    </div>
                  </div>
                </div>
                <table>
                  <tr>
                    <td width="300px">

                    </td>
                    <td width="100px">

                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">

                    </td>
                  </tr>
                </table>
              </div>

            </div>
          </div>
        </div>
        <div class="item_dv quick_links_box">
          <div class="nan_bg">
            <div class="links_box w">
              <div style="margin-top:10px">
                <div id="demo">
                  <div id="indemo">
                    <VueMarquee :pause-on-hover="true" :duration="50" :clone="true">
                      <li v-for="i in links" class="lkli" :key="i.id">
                        <a @click="jumpLink(i)">
                          <img :src="i.logo" alt="">
                        </a>
                      </li>
                    </VueMarquee>
                  </div>
                </div>
              </div>
              <ul class="clearfix">
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="hidden-sm-and-up">
      <Header />
      <div class="top_big_img_box" style="height:160px;">
        <!--slider-->
        <template v-if="adTypes.find(v => v.id === 80)">
          <el-carousel trigger="click" height="160px">
            <el-carousel-item v-for="i in adTypes.find(v => v.id === 80).subMap" :key="i.id">
              <div class="silder-main-img" :key="i.id"
                :style="`height:100%;background:url(${i.pic}) 50% bottom no-repeat;background-size:100% 100%;`">
              </div>
            </el-carousel-item>
          </el-carousel>
        </template>
        <!--slider-->
      </div>
      <div class="weui-tab pro_weui_tab">
        <div class="weui-navbar">

          <a @click="activeIndex = 0" class="weui-navbar__item" :class="activeIndex === 0 ? 'weui-bar__item--on' : ''">
            <i class="h3_icon"></i>集团要闻
          </a>
          <a @click="activeIndex = 1" class="weui-navbar__item" :class="activeIndex === 1 ? 'weui-bar__item--on' : ''">
            <i class="h3_icon dj_icon"></i>党建工作
          </a>
          <a @click="activeIndex = 2" class="weui-navbar__item" :class="activeIndex === 2 ? 'weui-bar__item--on' : ''">
            <i class="h3_icon dt_icon"></i>企业动态
          </a>
        </div>
        <div class="weui-tab__bd">
          <div v-if="activeIndex === 0" id="tab1" class="weui-tab__bd-item"
            :class="activeIndex === 0 ? 'weui-tab__bd-item--active' : ''">
            <!--公司新闻-->
            <div class="m_item">
              <div class="content_dv">
                <div class="weui-panel weui-panel_access">
                  <!-- <div class="weui-panel__hd">图文组合列表</div> -->
                  <div class="weui-panel__bd">
                    <template v-for="i in news">
                      <a @click="jumpToNews('集团要闻', i)" :key="i.id" class="weui-media-box weui-media-box_appmsg">
                        <div class="weui-media-box__hd">
                          <div class="imgBg_dv" v-html="i.pic"></div>
                        </div>
                        <div class="weui-media-box__bd">
                          <h4 class="weui-media-box__title">{{ i.title }}</h4>
                          <p class="weui-media-box__desc">
                            {{ i.content }}</p>
                        </div>
                      </a>
                    </template>
                  </div>
                  <div class="weui-panel__ft">
                    <a @click="jumpToNews('集团要闻')" style="cursor:pointer;"
                      class="weui-cell weui-cell_access weui-cell_link">
                      <div class="weui-cell__bd">more</div>
                      <span class="weui-cell__ft"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeIndex === 1" id="tab2" class="weui-tab__bd-item"
            :class="activeIndex === 1 ? 'weui-tab__bd-item--active' : ''">
            <!--党建工作-->
            <div class="m_item">
              <div class="content_dv">
                <div class="weui-panel weui-panel_access">
                  <div class="weui-panel__bd">
                    <div @click="jumpToNews('党建工作', article)" class="weui-media-box weui-media-box_text">
                      <h4 class="weui-media-box__title">{{ article.title }}</h4>
                      <p class="weui-media-box__txt"> {{ article.content }}
                      </p>
                    </div>
                  </div>
                  <div class="weui-panel__ft">
                    <a @click="jumpToNews('党建工作')" style="cursor:pointer;"
                      class="weui-cell weui-cell_access weui-cell_link">
                      <div class="weui-cell__bd">more</div>
                      <span class="weui-cell__ft"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div v-if="activeIndex === 2" id="tab3" class="weui-tab__bd-item"
            :class="activeIndex === 2 ? 'weui-tab__bd-item--active' : ''">
            <!--企业动态-->
            <div class="m_item">
              <div class="content_dv">
                <div class="weui-panel weui-panel_access">
                  <!-- <div class="weui-panel__hd">图文组合列表</div> -->
                  <div class="weui-panel__bd">
                    <template v-for="i in activities">
                      <a @click="jumpToNews('企业动态', i)" class="weui-media-box weui-media-box_appmsg" :key="i.id">
                        <div class="weui-media-box__hd">
                          <div class="imgBg_dv" v-html="i.pic"></div>
                        </div>
                        <div class="weui-media-box__bd">
                          <h4 class="weui-media-box__title">{{ i.title }}</h4>
                          <p class="weui-media-box__desc">{{ i.content }}</p>
                        </div>
                      </a>
                    </template>
                  </div>
                  <div class="weui-panel__ft">
                    <a @click="jumpToNews('企业动态')" class="weui-cell weui-cell_access weui-cell_link">
                      <div class="weui-cell__bd">more</div>
                      <span class="weui-cell__ft"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--近期活动-->
      <div class="m_item">
        <h3>
          <i class="h3_icon a_icon"></i>近期活动
          <span>Recent Activities</span>
        </h3>
        <div class="content_dv activity_content">
          <div class="weui-flex">
            <div class="weui-flex__item">
              <a
                :href="adTypes.find(v => v.id === 81) && adTypes.find(v => v.id === 81).subMap[0] ? adTypes.find(v => v.id === 81).subMap[0].link : ''">
                <img class="img_dv img_a"
                  :src="adTypes.find(v => v.id === 81) && adTypes.find(v => v.id === 81).subMap[0] ? adTypes.find(v => v.id === 81).subMap[0].pic : ''"
                  alt="">
              </a>
            </div>
            <div class="weui-noflex__item">
              <a
                :href="adTypes.find(v => v.id === 84) && adTypes.find(v => v.id === 84).subMap[0] ? adTypes.find(v => v.id === 84).subMap[0].link : ''">
                <img class="img_dv img_b"
                  :src="adTypes.find(v => v.id === 84) && adTypes.find(v => v.id === 84).subMap[0] ? adTypes.find(v => v.id === 84).subMap[0].pic : ''"
                  alt="">
              </a>
            </div>
          </div>
          <div class="weui-flex">
            <div class="weui-flex__item">
              <a
                :href="adTypes.find(v => v.id === 85) && adTypes.find(v => v.id === 85).subMap[0] ? adTypes.find(v => v.id === 85).subMap[0].link : ''">
                <img class="img_dv img_a"
                  :src="adTypes.find(v => v.id === 85) && adTypes.find(v => v.id === 85).subMap[0] ? adTypes.find(v => v.id === 85).subMap[0].pic : ''"
                  alt="">
              </a>
            </div>
          </div>
          <div class="weui-panel__ft">
            <a class="weui-cell weui-cell_access weui-cell_link" @click="jumpToNews('近期活动')">
              <div class="weui-cell__bd">more</div>
              <span class="weui-cell__ft"></span>
            </a>
          </div>
        </div>
      </div>

      <!--南京文化-->
      <div class="m_item">
        <h3>
          <i class="h3_icon a_icon"></i>国企改革 三年行动
          <span>City & Culture</span>
        </h3>
        <div class="content_dv" style="margin-top: 10px;">
          <a @click="jumpToNews('国企改革')">
            <div class="imgbox bottom_imgbox_s"><img
                :src="adTypes.find(v => v.id === 82) && adTypes.find(v => v.id === 82).subMap[0] ? adTypes.find(v => v.id === 82).subMap[0].pic : ''"
                alt="" style="width:80%;height:auto;margin:0 auto;display:block;"></div>
          </a>
        </div>
        <div class="weui-panel__ft">
          <a class="weui-cell weui-cell_access weui-cell_link" @click="jumpToNews('国企改革')">
            <div class="weui-cell__bd">more</div>
            <span class="weui-cell__ft"></span>
          </a>
        </div>
      </div>

      <!--友情链接-->
      <div class="m_item">
        <div class="content_dv">
          <div class="item_dv quick_links_box">
            <div class="nan_bg">
              <div class="links_box">
                <VueMarquee :pause-on-hover="true" :duration="50" :clone="true">
                  <li v-for="i in links" class="lkli" :key="i.id" style="width: 80px;height: 80px;">
                    <a @click="jumpLink(i)">
                      <img :src="i.logo" alt="">
                    </a>
                  </li>
                </VueMarquee>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer />
  </div>
</template>

<script>
import {
  getLyAdCategoryApi,
  getArticleCategoryApi,
  getLyFriendLinkApi, getLvYouArticleListApi
} from '@/api'
import { mapActions, mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import { isMobile, unescapeHtml } from "@/utils/utils";
import VueMarquee from 'marquee-vue2';
import Header from "@/components/Header.vue";

export default {
  components: { Footer, VueMarquee, Header },
  data() {
    return {
      // adTypes: [],
      // links: [],
      news: [],
      article: {},
      activities: [],
      keyword: '',
      imgHeight: '600px',
      activeIndex: 0
    }
  },
  computed: {
    ...mapGetters(['config', 'types', 'adTypes', 'links']),
  },
  async mounted() {
    // const res = await getLyWebSiteApi();
    // this.updateConfig(res.data);
    // await this.getLyAdCategoryApi()
    var h = document.documentElement.clientHeight || document.body.clientHeight;

    var s_h = h - 140;
    this.imgHeight = s_h + 'px';
    await this.getNewsContent();
    await this.getActivities();
    await this.getDJContent();
    // await this.getLyFriendLinkApi();
    this.$nextTick(() => {
      // eslint-disable-next-line no-undef
      var m_left = $('.wrapper')[0].offsetLeft;
      // eslint-disable-next-line no-undef
      $('.left_z,.right_z').css({ width: m_left + 'px' });
    })
  },
  methods: {
    ...mapActions(['updateAdTypes', 'updateCurrentSubType', 'updateCurrentType', 'updateTypes', 'updateConfig']),
    jumpLink(link) {
      if (link.url) {
        window.open(link.url, '_blank');
      }
      else {
        let { href } = this.$router.resolve({ name: 'Friend', query: { id: link.id } });
        window.open(href, '_blank');
      }
    },
    jumpToNews(name, article) {
      for (const type of this.types) {
        if (type.name === name) {
          this.updateCurrentType(type);
          if (type.subMap && type.subMap.length > 0) {
            this.updateCurrentSubType(type.subMap[0]);
          }
          else {
            this.updateCurrentSubType(null);
          }
          if (article) {
            this.$router.push({ name: 'Detail', query: { articleId: article ? article.id : '' } });
          }
          else {
            this.$router.push({ name: 'Type', query: { articleId: article ? article.id : '' } });
          }
          return;
        } else {
          for (const subType of type.subMap) {
            if (subType.name === name) {
              this.updateCurrentType(type);
              this.updateCurrentSubType(subType);
              if (article) {
                this.$router.push({ name: 'Detail', query: { articleId: article ? article.id : '' } });
              }
              else {
                this.$router.push({ name: 'Type', query: { articleId: article ? article.id : '' } });
              }
              return;
            }
          }
        }
      }
    },

    async getLyAdCategoryApi() {
      const res = await getLyAdCategoryApi();
      this.adTypes = res.data;
      for (const adType of this.adTypes) {
        const res = await getArticleCategoryApi({ cid: adType.id });
        adType.subMap = res.data.data;
      }
      this.updateAdTypes(this.adTypes);
    },
    async getLyFriendLinkApi() {
      const res = await getLyFriendLinkApi();
      this.links = res.data;
    },

    updateAnimation() {
      /*新增代码（控制nav选中位置）*/
      // eslint-disable-next-line no-undef
      $('.news_navs li').mouseover(function () {
        // eslint-disable-next-line no-undef
        var _th = $(this);
        const divs = this.getElementsByTagName('div');
        if (divs.length > 0) {
          // eslint-disable-next-line no-undef
          $(divs[0]).css({ "display": "block" });
        }
        var _li_left = _th.position().left;
        // eslint-disable-next-line no-undef
        var sbox = $('.news_navs .selected_div');
        // eslint-disable-next-line no-undef
        var right_bd = $('.news_navs .right_i');
        // eslint-disable-next-line no-undef
        var left_bd = $('.news_navs .left_i');
        if (_th.index() == 0) {
          _li_left = _li_left - 15;
        }
        sbox.stop().animate({ left: _li_left + 10 * 1 }, 300);
        right_bd.stop().animate({ left: _li_left + 70 * 1 }, 300);
        left_bd.stop().animate({ width: _li_left + 10 * 1 }, 300);
        _th.addClass('cur').siblings().removeClass('cur');
      })
      // eslint-disable-next-line no-undef
      $('.news_navs li').mouseout(function () {
        // eslint-disable-next-line no-undef
        const divs = this.getElementsByTagName('div');
        if (divs.length > 0) {
          // eslint-disable-next-line no-undef
          $(divs[0]).css({ "display": "none" });
        }
        // eslint-disable-next-line no-undef
        var sbox = $('.news_navs .selected_div');
        // eslint-disable-next-line no-undef
        var right_bd = $('.news_navs .right_i');
        // eslint-disable-next-line no-undef
        var left_bd = $('.news_navs .left_i');
        sbox.stop().animate({ left: -5 }, 300);
        right_bd.stop().animate({ left: 55 }, 300);
        left_bd.stop().animate({ width: 0 }, 300);
        // eslint-disable-next-line no-undef
        $('.news_navs li').eq(0).addClass('cur').siblings().removeClass('cur');
      });

    },
    async getNewsContent() {
      const res = await getLvYouArticleListApi({ cid: 76, page: 1, limit: 20 });
      this.news = res.data.data.filter(v => v.content && v.content.indexOf('<img') >= 0).slice(0, 3);
      for (const i of this.news) {
        // 定义正则表达式
        const regex = /<img[^>]*>/g; // 匹配所有的 <img> 标签

        // 使用match()函数获取所有匹配到的结果
        const imgTags = i.content.match(regex);
        let pic = imgTags[0].replaceAll('src="/weaver/weaver.file', 'src="https://oa.njlyjt.cn/weaver/weaver.file');
        if (isMobile()) {
          i.pic = pic.replace('<img ', '<img style="width:100%;height:100%;"');
        } else {
          i.pic = pic.replace('<img ', '<img style="width:325px;height:216px"');
        }
        const contents = i.content.replace(/<[^>]*>/g, '').split('!@#$%^&*');
        let c = '';
        if (contents.length > 1) {
          c = contents[1].substr(0, 120);
        } else {
          c = contents[0].substr(0, 120);
        }
        // 将字符串s里的html转义字符转为正常的字符
        i.content = unescapeHtml(c);
      }

      this.$nextTick(() => {
        //热门资讯
        // eslint-disable-next-line no-undef
        var aLi = $('.news-title li');
        // eslint-disable-next-line no-undef
        var aPic = $('.pic li');
        var num = 0;
        var timer = null;

        aLi.mouseenter(function () {
          // eslint-disable-next-line no-undef
          var index = $(this).index();
          num = index;
          tab();
          window.clearInterval(timer);
        });
        aLi.mouseleave(function () {
          timer = setInterval(function () {
            num++;
            if (num >= aLi.length)
              num = 0;
            tab();
          }, 3000);
        });
        timer = setInterval(function () {
          num++;
          if (num >= aLi.length)
            num = 0;
          tab();
        }, 3000);

        function tab() {
          aLi.removeClass('active').eq(num).addClass('active');
          aPic.hide().eq(num).show();
        }
      });
    },
    async getActivities() {
      const res = await getLvYouArticleListApi({ cid: 77, page: 1, limit: 20 });
      this.activities = res.data.data.filter(v => v.content && v.content.indexOf('<img') >= 0).slice(0, 3);
      for (const i of this.activities) {
        // 定义正则表达式
        const regex = /<img[^>]*>/g; // 匹配所有的 <img> 标签

        // 使用match()函数获取所有匹配到的结果
        const imgTags = i.content.match(regex);
        let pic = imgTags[0].replaceAll('src="/weaver/weaver.file', 'src="https://oa.njlyjt.cn/weaver/weaver.file');
        if (isMobile()) {
          i.pic = pic.replace('<img ', '<img style="width:100%;height:100%;"');
        } else {
          i.pic = pic.replace('<img ', '<img style="width:325px;height:216px"');
        }
        const contents = i.content.replace(/<[^>]*>/g, '').split('!@#$%^&*');
        let c = '';
        if (contents.length > 1) {
          c = contents[1].substr(0, 120);
        } else {
          c = contents[0].substr(0, 120);
        }
        // 将字符串s里的html转义字符转为正常的字符
        i.content = unescapeHtml(c);
      }
    },
    async getDJContent() {
      const res = await getLvYouArticleListApi({ cid: 73, page: 1, limit: 1 });
      this.article = res.data.data[0];
      let c = '';
      const contents = this.article.content.replace(/<[^>]*>/g, '').split('!@#$%^&*');
      if (contents.length > 1) {
        c = contents[1].substr(0, 200);
      } else {
        c = contents[0].substr(0, 200);
      }
      // 将字符串s里的html转义字符转为正常的字符
      this.article.content = unescapeHtml(c);
    }
  },

}
</script>

<style scoped>
.main_wrap {
  background: url(../assets/images/new_a_bg.gif) repeat-y center bottom;
  background-size: 100%;
}

/deep/ .el-input__inner {
  height: 30px;
  line-height: 30px;
}

b {
  font-weight: bold
}

html {
  color: #424242
}

body {
  background-color: #ffffff;
  font-size: 16px;
  height: 100%;
  margin: 0 auto;
  max-width: 640px;
  min-width: 320px;
  overflow-x: hidden;
  width: 100%;
}

.fl {
  float: left
}

.fr {
  float: right
}

.hide {
  display: none !important;
}

.center-text {
  text-align: center
}

.m-auto {
  margin: 0 auto
}

.swiper-container img {
  width: 100%
}

.imgbox img {
  display: block;
  width: 100%;
}

.bgf4f4f6,
.bgf4f4f6 body {
  background: #f4f4f6;
}

.bgf6f6f6,
.bgf6f6f6 body {
  background: #f6f6f6;
}

.swiper-container {
  width: 100%;
}

.swiper-container img {
  display: block;
  width: 100%;
}


.swiper-pagination-bullet {
  background-color: #FFF;
}

.swiper-pagination-bullet-active {
  background-color: #ffc811;
  width: 12px;
  border-radius: 4px;
}

.flex—bottom {
  position: fixed;
  bottom: 0;
  z-index: 2;
}

.btn_t_a {
  display: block;
  width: 90%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #ffc811;
  border-radius: 5px;
  font-size: 16px;
  margin: auto;
  margin-bottom: 5px;
}

.weui-btn_orange {
  background-color: #ffc811;
  color: #333;
}

.weui-btn.weui-btn_orange:not(.weui-btn_disabled):active {
  background-color: #c0960c;
}

.ping_a_dv {
  padding: 0px 15px 5px 15px;
}

.placeholder {
  margin: 5px;
}

.b_icon {
  display: block;
  width: 27px;
  height: 23px;
  margin: auto;
}

.main_b_50 {
  padding-bottom: 50px;
}

.over2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.ca2 {
  color: #9da0a2;
}

.bd_bottom {
  border-bottom: 1px solid #e5e5e5;
}

.bd_bottom_af {
  position: relative;
}

.bd_bottom_af:after {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 1px;
  border-bottom: 1px solid #e5e5e5;
  color: #e5e5e5;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
  left: 15px;
}

.bd_top {
  border-top: 1px solid #e5e5e5;
}

.btn_span {
  background: #ffc811;
  font-size: 14px;
  text-align: center;
  padding: 8px 0;
  border-radius: 3px;
  display: block;
  width: 90px;
}

.weui-media-box__title {
  font-size: 14px;
}

.m_item {
  padding: 10px;
}

.m_item h3 {
  font-size: 14px;
  color: #d1a262
}

.m_item h3 span {
  font-size: 12px;
  padding: 0 1px;
  color: #b4b4b5
}

i.h3_icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(../assets/images/title_r_icon_02.png) no-repeat;
  background-size: 100%;
  vertical-align: -2px;
  margin-right: 5px;
}

i.dj_icon {
  background-image: url(../assets/images/title_r_icon_03.png);
}

i.dt_icon {
  background-image: url(../assets/images/title_r_icon_01.png);
}

i.a_icon {
  background-image: url(../assets/images/title_l_icon_01.png);
}

/* .m_item .content_dv .weui-media-box_appmsg .weui-media-box__hd{width:80px;height:80px;} */
.m_item .content_dv .imgBg_dv {
  width: 100%;
  height: 100%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center 0;
}

.m_item .content_dv .weui-media-box__txt {
  font-size: 13px;
  color: #999;
}

.m_item .activity_content {
  padding: 10px 0;
}

.m_item .activity_content img {
  display: block;
  width: 100%;
}

.m_item .activity_content .img_dv {
  height: 120px;
  background-size: 100%;
  background-position: center 0;
  background-repeat: no-repeat;
}

.m_item .activity_content .img_b {
  width: 120px;
  margin: 0 0 10px 10px;
  background-size: auto 100%;
}

.m_item .time_p {
  font-size: 10px;
  color: #666;
}

.secondaryContent {
  min-height: 300px;
  padding: 10px 0;
}

.secondaryContent .nav_b .l_dv i {
  display: block;
  width: 30px;
  height: 2px;
  background: #d1a262;
  margin-top: 15px;
}

.secondaryContent .nav_b a {
  display: block;
  width: 80%;
  padding: 5px 0;
  background: #FFF;
  border: 1px solid #d1a262;
  color: #d1a262;
  text-align: center;
  font-size: 12px;
  margin: auto;
}

.secondaryContent .nav_b a.cur {
  background: #d1a262;
  color: #FFF;
}

.detailContent img {
  display: block;
  max-width: 100% !important;
  width: 100%;
}

.detailContent {
  font-size: 12px !important;
  padding: 10px;
  line-height: 22px !important;
}

.detailContent h1,
.detailContent h2,
.detailContent h3,
.detailContent h4,
.detailContent h5,
.detailContent h6 {
  font-size: 16px !important;
  padding: 10px 0;
}

.weui-loadmore {
  display: none;
}


.quick_links_box {
  background: url(../assets/images/nan_bg.png) no-repeat right 0;
  background-size: auto 100%;
}

.quick_links_box .links_box li {
  text-align: center;
  margin-top: 5px;
  width: 110px;
  height: 110px;
  float: left;
}

.quick_links_box .links_box li a {
  margin: auto;
  -moz-box-shadow: 3px 3px 5px #333;
  -webkit-box-shadow: 3px 3px 5px #333;
  box-shadow: 3px 3px 5px #333;
  width: 90%;
  height: 90%;
  display: block;
  border-radius: 50%;
  overflow: hidden;
}

.quick_links_box .links_box li a img {
  display: block;
  width: 100%;
  height: 100%;
  margin: auto;
}

.quick_links_box .links_box ul {
  overflow: hidden;
  width: 1300px;
}

#demo {
  overflow: hidden;
  width: 1111px;
  height: 120px;
}

#demo-m {
  overflow: hidden;
  width: 100%;
  height: 90px;
}

#indemo {
  float: left;
  width: 800%;
}

#demo1 {
  float: left;
}

#demo2 {
  float: left;
}

.weui-footer {
  padding: 10px 0;
  background: url(../assets/images/news/footer_bg.gif) no-repeat center 0;
  color: #FFF;
  font-size: 11px;
}

.weui-footer a {
  color: #FFF;
  font-size: 12px;
}


.pro_weui_tab .weui-navbar__item {
  padding: 10px 0;
  background: transparent;
}

.pro_weui_tab .weui-navbar__item.weui-bar__item--on {
  background: transparent;
  color: #d1a262;
}

.pro_weui_tab .m_item {
  padding: 0 10px;
}

.pro_weui_tab .weui-panel:before {
  display: none;
}

.weui-cell_link .weui-cell__bd {
  text-align: right;
  color: #d1a262;
}
</style>
