<template>
  <div id="app" :class="isGray ? 'grayTheme': ''" v-loading="loading">
    <template v-if="types && types.length > 0">
      <router-view />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getLvYouSysConfigApi, getLyArticleOneTypeApi, getLyArticleSubTypeApi, getLyAdCategoryApi, getArticleCategoryApi, getLyWebSiteApi, getLyFriendLinkApi } from './api'
import { isMobile } from './utils/utils';

export default {
  name: 'App',
  data() {
    return {
      isGray: false,
      // types: [],
      loading: false,
      links: []
    }
  },
  async mounted () {
    getLvYouSysConfigApi('SYSZH').then(res=>{
      this.isGray = res.data.info === '1';
    });
    this.loading = true;
    const res = await getLyWebSiteApi();
    this.updateConfig(res.data);
    await this.getLyArticleType();
    await this.getLyAdCategoryApi();
    await this.getLyFriendLinkApi();
    this.loading = false;
  },
  computed: {
    ...mapGetters(['types'])
  },
  methods: {
    ...mapActions(['updateTypes', 'updateAdTypes', 'updateConfig', 'updateLinks']),
    async getLyFriendLinkApi() {
      const res = await getLyFriendLinkApi();
      this.links = res.data;
      this.updateLinks(this.links);
    },
    async getLyArticleType() {
      if (this.types && this.types.length > 0) {
        return;
      }
      else {
        const res = await getLyArticleOneTypeApi();
        let types = res.data;
        for (const adType of types) {
          const res1 = await getLyArticleSubTypeApi({cid: adType.id});
          adType.subMap = res1.data;
          if (adType.id === 84 || adType.id === 95 || (!isMobile() && adType.id === 72) || adType.id === 79) {
            adType.status = 0;
          } else {
            adType.status = 1;
          }
          // 
          for (const s of res1.data) {
            if (s.id === 81) {
              const res2 = await getLyArticleSubTypeApi({cid: s.id});
              s.subMap = res2.data;
            }
          }
        }
      // this.types = types;
        this.updateTypes(types);
      }
      
    },
    async getLyAdCategoryApi() {
      const res = await getLyAdCategoryApi();
      this.adTypes = res.data;
      for (const adType of this.adTypes) {
        const res = await getArticleCategoryApi({cid: adType.id});
        adType.subMap = res.data.data;
      }
      this.updateAdTypes(this.adTypes);
    },
  },
}
</script>

<style>
#app {
  font-family: SimSun;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  widows: 100vh;
}
.grayTheme {
  FILTER: gray;
  -webkit-filter: grayscale(100%);
}
</style>
