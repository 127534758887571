import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import VueMarquee from 'marquee-vue2';
Vue.config.productionTip = false
import './css/base.less'
import less from 'less'
Vue.use(less)
Vue.use(ElementUI);
Vue.use(VueMarquee)
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
import Axios from "./Axios";
import config from "./config";
Vue.use(Axios);
Vue.prototype.baseurl = config.baseurl;

new Vue({
  router,
  store,
  data() {
    return {
    }
  },
  render: h => h(App)
}).$mount('#app')
