/*
 * @Description:
 * @Author: wangying
 * @Date: 2021-10-26 14:44:49
 */


import { Axios } from "../Axios";

//获取所有一级分类
export const getLyArticleOneTypeApi = async ()  => {

  return await Axios(`base/lyApi/api/getLyArticleOneTypeApi`);
};

//分页查询文章接口
export const getLvYouArticleApi = async params => {

  return await Axios(`base/lyApi/api/getLvYouArticleApi?id=${params.articleId}`);
};
// 分页查询文章接口
export const getLvYouArticleListApi = async params => {
  return await Axios(`base/lyApi/api/getLvYouArticleListApi?cid=${params.cid || ''}&page=${params.page}&limit=${params.limit}&sy=${params.sy || ''}&title=${params.title||''}`);
}

export const getFrontChannelArticleTypeSubApi = async params => {
  return await Axios(`base/channelArticleApi/api/getFrontChannelArticleTypeSubApi?typeId=${params.typeId}`);
}

//获取轮播图分类
export const getLyAdCategoryApi = async () => {
  return await Axios(`base/lyApi/api/getLyAdCategoryApi`);
}

//获取文章分类
export const getArticleCategoryApi = async params => {
  return  await Axios(`base/lyApi/api/getLyAdApi?cid=${params.cid}`);
}

//获取友情链接
export const getLyFriendLinkApi = async () => {
  return await Axios('base/lyApi/api/getLyFriendLinkApi');
}


//获取文章列表
export const getLyArticleSubTypeApi = async params => {
  return await Axios('base/lyApi/api/getLyArticleSubTypeApi?cid='+params.cid)
}

export const getLyWebSiteApi = async () => {
  return await Axios('base/lyApi/api/getLyWebSiteApi')
}
//获取系统参数详情
export const getLvYouSysConfigApi = async (appCode)=>{
  return await Axios('base/lyApi/api/getLvYouSysConfigApi?appCode='+appCode)
}



