// 创建一个js方法，用来判断是否运行中移动端浏览器中
export function isMobile() {
    const userAgent = window.navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

export function unescapeHtml(str) {
    return str.replace(/&amp;|&lt;|&gt;|&quot;|&#39;|&ldquo;|&rdquo;|&middot;|&nbsp;/g, function (m) {
        switch (m) {
            case '&amp;':
                return '&';
            case '&lt;':
                return '<';
            case '&gt;':
                return '>';
            case '&quot;':
                return '"';
            case '&#39;':
                return "'";
            case '&ldquo;':
                return '“'
            case '&rdquo;':
                return '”'
            case '&middot;':
                return '·'
            case '&nbsp;':
                return ' '
        }
    });
}

