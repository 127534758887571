/*
 * @Description:
 * @Author: wangying
 * @Date: 2021-10-26 14:36:37
 */


const config = {
  // baseurl: "http://192.168.200.11/",
  baseurl: "https://www.njlyjt.cn/",
  // baseurl: "http://47.101.128.41/",
  // baseurl: "http://gsc.see.org.cn/",
};
// export default config;
module.exports = config;
