import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    types: [],
    currentType: null,
    currentSubType: null,
    adTypes: [],
    config: null,
    links: []
  },
  mutations: {
    setTypes(state, types) {
      state.types = types;
    },
    setCurrentType(state, type) {
      state.currentType = type;
    },
    setCurrentSubType(state, subType) {
      state.currentSubType = subType;
    },
    setAdTypes(state, adTypes) {
      state.adTypes = adTypes;
    },
    setConfig(state, config) {
      state.config = config;
    },
    setLinks(state, links) {
      state.links = links;
    },

  },
  actions: {
    updateTypeWithName({state,commit}, name) {
      for (const type of state.types) {
        if (type.name === name) {
          commit('setCurrentType', type);
          commit('setCurrentSubType', null);
          break;
        } else {
          for (const subType of type.subMap) {
            if (subType.name === name) {
              commit('setCurrentType', type);
              commit('setCurrentSubType', subType);
              break;
            }
          }
        }
      }
    },
    updateTypeWithCid({state,commit}, cid) {
      for (const type of state.types) {
        // console.log('cid:', cid);
        // console.log('type:', type.id);
        if (type.id === cid) {
          commit('setCurrentType', type);
          commit('setCurrentSubType', null);
          break;
        } else {
          for (const subType of type.subMap) {
            if (subType.id === cid) {
              commit('setCurrentType', type);
              commit('setCurrentSubType', subType);
              break;
            }
            else {
              if (subType.subMap && subType.subMap.length > 0) {
                for (const thirdType of subType.subMap) {
                  if (thirdType.id === cid) {
                    commit('setCurrentType', type);
                    commit('setCurrentSubType', subType);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    },
    clearType({commit}) {
      commit('setCurrentType', null);
      commit('setCurrentSubType', null);
    },
    updateTypes({ commit }, types) {
      commit('setTypes', types);
    },
    updateCurrentType({ commit }, type) {
      commit('setCurrentType', type);
    },
    updateCurrentSubType({ commit }, subType) {
      commit('setCurrentSubType', subType);
    },
    updateAdTypes({ commit }, adTypes) {
      commit('setAdTypes', adTypes);
    },
    updateConfig({ commit }, config) {
      commit('setConfig', config);
    },
    updateLinks({ commit }, links) {
      commit('setLinks', links);
    }
  },  getters: {
    types: state => state.types,
    currentType: state => state.currentType,
    currentSubType: state => state.currentSubType,
    adTypes: state => state.adTypes,
    config: state => state.config,
    links: state => state.links
  },
  plugins: [
      createPersistedState({
        key: 'lvyoujt-vuex',
        storage: window.localStorage,
        render(state) {
          return {...state}
        }
      })
  ]
});
