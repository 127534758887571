/*
 * @Description:
 * @Author: wangying
 * @Date: 2021-09-26 10:48:48
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
            flag: true,
            btmNav: true,
            flagNam: 0
        }
    },
    {
        path: '/type',
        name: 'Type',
        component: () => import(/* webpackChunkName: "about" */ '../views/Type.vue'),
        meta: {
            flag: true,
            btmNav: true,
            flagNam: 1
        }
    },
    {
        path: '/detail',
        name: 'Detail',
        component: () => import(/* webpackChunkName: "about" */ '../views/Detail.vue'),
        meta: {
            flag: true,
            btmNav: true,
            flagNam: 2
        }
    },
    {
        path: '/friend',
        name: 'Friend',
        component: () => import(/* webpackChunkName: "about" */ '../views/Friend.vue'),
        meta: {
            flag: true,
            btmNav: false,
            flagNam: 3
        }
    },
    {
        path: '/searchlist',
        name: 'SearchList',
        component: () => import(/* webpackChunkName: "about" */ '../views/SearchList.vue'),
        meta: {
            flag: true,
            btmNav: false,
            flagNam: 3
        }
    },
]

const router = new VueRouter({
  routes
})

export default router
